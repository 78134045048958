import EtrdInput from '@/ui-kit/EtrdInput';
import { useField } from 'formik';
import React from 'react';

export const EtrdFormField = (props: any) => {
  const [field, meta] = useField(props);
  const helperText = props.instantvalidate
    ? field.value && meta.error
    : meta.touched && meta.error
    ? meta.error
    : null;

  const error = props.instantvalidate
    ? meta.error && field.value
    : meta.touched && meta.error
    ? meta.error
    : null;

  return (
    <EtrdInput
      mb={2}
      error={error}
      helperText={helperText}
      {...field}
      {...props}
      label={props.placeholder}
    />
  );
};
