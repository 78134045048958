import * as React from 'react';
import { EtrdFormField } from './EtrdForm/EtrdFormField';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export const EtrdPasswordInput = (props: any) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <EtrdFormField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex="-1"
              aria-label={props.eyePreviewLabel}
              onClick={() => setShowPassword(!showPassword)}
            >
              {!showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
