import { useField } from 'formik';
import React from 'react';
import EtrdInput from '../EtrdInput';

export const EtrdFormField = (props: any) => {
  const [field, meta] = useField(props);
  return (
    <EtrdInput
      error={!!(meta.touched && meta.error ? meta.error : null)}
      helperText={meta.touched && meta.error ? meta.error : null}
      mb={2}
      {...field}
      {...props}
      label={props.placeholder}
    />
  );
};
export const EtrdHackyFormField = (props: any) => {
  const [field, meta] = useField(props);
  return (
    <EtrdInput
      error={!!(meta.touched && meta.error ? meta.error : null)}
      helperText={meta.touched && meta.error ? meta.error : null}
      mb={2}
      {...field}
      {...props}
      onChange={(e: any) => {
        field.onChange(e);
        props.onChange(e);
      }}
      label={props.placeholder}
    />
  );
};
