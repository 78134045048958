import { CardProps } from '@material-ui/core';
import { LayoutProps, SpaceProps } from 'styled-system';
import { EtrdCard } from '@/ui-kit/EtrdCard';
import React from 'react';
import GoHomeButton from '@/components/GoHomeButton/GoHomeButton';
export const CardWithGoHome = (props: CardProps & SpaceProps & LayoutProps) => {
  return (
    <>
      <GoHomeButton />
      <EtrdCard variant="outlined" {...props}>
        {props.children}
      </EtrdCard>
    </>
  );
};
