import React from 'react';
import EtrdButton from '@/ui-kit/EtrdButton';
import {
  EtrdCard,
  EtrdCardActions,
  EtrdCardContent,
  EtrdCardHeader,
} from '@/ui-kit/EtrdCard';
import useLogin, { LoginPayload } from '@/components/Login/hooks/useLogin';
import EtrdBox from '@/ui-kit/EtrdBox';
import EtrdFormHelperText from '@/ui-kit/EtrdForm/EtrdFormHelperText';
import { Form, Formik } from 'formik';
import { EtrdFormField } from '@/shared-components/DynamicForm/EtrdFormField';
import { Yup } from '@/shared-components/DynamicForm/Validator';
import { SchemaOf } from 'yup';
import { TFunction, useTranslation } from 'next-i18next';
import EtrdLink from '@/ui-kit/EtrdLink';
import ROUTES from 'constants/routes';
import EtrdTypography from '@/ui-kit/EtrdTypogrphy';
import EtrdGrid from '@/ui-kit/EtrdGrid';
import { EtrdPasswordInput } from '@/ui-kit/EtrdPasswordInput';
import EtrdAlert from '@/ui-kit/EtrdAlert';
import { CardWithGoHome } from '@/components/CardWithGoHome/CardWithGoHome';
import { validateUsername } from '@/utils/validateUsername';
const LoginSchema = (t: TFunction): SchemaOf<LoginPayload> =>
  Yup.object().shape({
    password: Yup.string().required(t('validations:requiredPassword')),
    username: Yup.string()
      .test('validUsername', t('validations:username'), validateUsername as any)
      .required(t('validations:requiredUsername')),
  });

const initialFormData: LoginPayload = { username: '', password: '' };
interface Props {
  confirmPasswordContext?: boolean;
}

const LoginForm: React.FC<Props> = ({ confirmPasswordContext }) => {
  const { handleLogin, errorsWithLogin } = useLogin();

  const { t } = useTranslation(['login', 'validations']);
  return (
    <EtrdGrid container justifyContent="center">
      <EtrdGrid item xs={12} sm={8} md={5}>
        <EtrdBox mb={2}>
          {confirmPasswordContext && (
            <EtrdAlert severity="warning">{t('firstLoginInfo')}</EtrdAlert>
          )}
        </EtrdBox>
        <Formik
          validateOnMount={false}
          initialValues={initialFormData}
          onSubmit={(values) => {
            handleLogin(values);
          }}
          validationSchema={LoginSchema(t)}
        >
          {() => (
            <Form data-test="login-form">
              <CardWithGoHome>
                <EtrdCardHeader title={t('signIn')} />
                <EtrdCardContent>
                  <EtrdFormField
                    data-test="username"
                    placeholder={t('username')}
                    name="username"
                    fullWidth
                  />
                  <EtrdPasswordInput
                    placeholder={t('password')}
                    data-test="password"
                    name="password"
                    fullWidth
                  />
                  <EtrdFormHelperText data-test="login-validation" error>
                    {errorsWithLogin}
                  </EtrdFormHelperText>
                  <EtrdBox
                    mt={1}
                    display="flex"
                    justifyContent="space-between"
                    style={{
                      justifyContent: 'space-between',
                      display: 'flex',
                    }}
                  >
                    <EtrdLink
                      data-test="forgotPasswordLink"
                      href={ROUTES.FORGOT_PASSWORD}
                    >
                      <EtrdTypography
                        color="textSecondary"
                        variant="subtitle2"
                        align="right"
                      >
                        {t('forgotPassword')}
                      </EtrdTypography>
                    </EtrdLink>
                    <EtrdLink href={ROUTES.REGISTRATION}>
                      <EtrdTypography
                        color="textSecondary"
                        variant="subtitle2"
                        align="right"
                      >
                        {t('createAccount')}
                      </EtrdTypography>
                    </EtrdLink>
                  </EtrdBox>
                </EtrdCardContent>
                <EtrdCardActions>
                  <EtrdButton
                    mt={2}
                    color={'secondary'}
                    size={'large'}
                    type="submit"
                    fullWidth
                    data-test="login"
                  >
                    {t('signIn')}
                  </EtrdButton>
                </EtrdCardActions>
              </CardWithGoHome>
            </Form>
          )}
        </Formik>
      </EtrdGrid>
    </EtrdGrid>
  );
};

export default LoginForm;
