import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import LoginForm from '@/components/Login/Form/LoginForm';
import BasicLayout from '@/layouts/BasicLayout/BasicLayout';
import { PAGES } from '@/store/modules/initActions/initActions';
import { ssrApi } from '@/repository/serviceContainer';
import useSsrAuth from '@/components/Hooks/Auth/useSsrAuth';
import ROUTES from 'constants/routes';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { IRootState } from '@/store/createStore';
import { useSelector } from 'react-redux';

const Login = () => {
  const { t } = useTranslation('common');
  const { name } = useSelector(
    (state: IRootState) => state.marketplace
  );
  return (
    <>
      <Head>
        <title>{t('login')} | {name}</title>
      </Head>
      <LoginForm />
    </>
  );
};

export async function getServerSideProps(ctx) {
  const { locale } = ctx;
  const { loggedTraderGuard, srrRedirect } = useSsrAuth();
  const isLoggedIn = loggedTraderGuard(ctx);

  if (isLoggedIn) {
    return srrRedirect(ROUTES.ROOT);
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'login',
        'validations',
        'common',
      ])),
    },
  };
}
Login.Layout = BasicLayout;
Login.displayName = PAGES.LOGIN;
export default Login;
// @ts-ignore
