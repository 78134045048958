import { FormHelperText, FormHelperTextProps } from '@material-ui/core';
import React from 'react';

interface EtrdFormHelperTextProps extends FormHelperTextProps {
  hide?: boolean;
}

const EtrdFormHelperText = (props: EtrdFormHelperTextProps) => {
  const { hide = false } = props;
  return (
    <>
      {!hide && <FormHelperText {...props}>{props.children}</FormHelperText>}{' '}
    </>
  );
};

export default EtrdFormHelperText;
